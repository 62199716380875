import {Injectable} from '@angular/core';

import {Message, MessageService} from 'primeng/api';
import {Observable, Subscriber} from 'rxjs';
import {NgEventBus} from 'ng-event-bus';

import {ToastParams} from '../../../models/toast-params';
import {ToastOption} from '../../../models/enums/toast-option';

@Injectable()
export class ToastService {

  constructor(private messageService: MessageService,
              private eventBus: NgEventBus) {
  }

  public observe(parameters: ToastParams): Observable<ToastOption> {
    const id: string = (Math.random() + 1).toString(36).substring(2);

    const message: Message = {
      id,
      severity: 'custom',
      summary: parameters.title,
      detail: parameters.message,
      life: parameters.timeout,
      closable: parameters.closable,
      data: {
        action1: parameters.action1 ? String(parameters.action1).trim().toUpperCase() : undefined,
        action2: parameters.action2 ? String(parameters.action2).trim().toUpperCase() : undefined
      }
    };

    this.messageService.add(message);


    return new Observable<ToastOption>((s: Subscriber<ToastOption>) => {


      s.next(ToastOption.NONE);
      s.complete()
    });
  }

  public show(message: string, timeout: number, action1?: string, action2?: string): void {
    this.messageService.add({severity: 'success', summary: 'Success', detail: 'Message Content'});
  }
}
